import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { MyRoutingModule } from './my-routing.module';

import { MyComponent } from './my.component';
import { BookingsComponent } from './bookings/bookings.component';
import { HistoryComponent } from './history/history.component';
import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    MyComponent,
    BookingsComponent,
    HistoryComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MyRoutingModule,
    NgbRatingModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    CountdownModule
  ]
})

export class MyModule { }
