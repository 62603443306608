import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MyComponent } from './my.component';
import { BookingsComponent } from './bookings/bookings.component';
// import { HistoryComponent } from './history/history.component';
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [
    {
        path: 'my',
        component: MyComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'bookings',
                component: BookingsComponent,
                canActivate: [AuthGuardService]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MyRoutingModule { }