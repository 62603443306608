import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  userId: string;

  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {
    this.afAuth.authState.subscribe((auth) => {
      if (auth) this.userId = auth.uid
    });
  }

  processPayment(bookingid, record) {
    return this.firestore.collection('payments').doc(bookingid).set(record);
  }

  completePayment(bookingid) {
    return this.firestore.doc(`bookings/${bookingid}`).valueChanges();
  }

}
