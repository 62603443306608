import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from '@angular/fire/functions';
import { auth } from 'firebase/app';
import { AuthService } from '../services/auth.service';
import { PaymentService } from '../services/payment.service';
import { BookingService } from '../services/booking.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

//unsubscribe
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent extends BaseComponent implements OnInit, OnDestroy {

  private geoCoder;
  latitude: number;
  longitude: number;
  zoom: number;
  public isBlocked: boolean;
  createAccount: FormGroup;

  // @ViewChild('search')
  // public searchElementRef: ElementRef;

  // @ViewChild('room')
  // public roomElementRef: ElementRef;

  formView: boolean = true;

  public template: string;
  public bookingData;

  handler: any;
  userId: string;
  userPhoto: string;
  userName: string;
  userEmail: string;
  service: string;
  duration: string;
  room: string;
  address: string;
  timeDate: string;
  therapist: string;
  price: number;
  reply: any;
  valid: boolean = false;
  //emailVerified: boolean = false;
  bookingId: string;

  constructor(
    public bookingSvc: BookingService,
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    public auth: AuthService,
    private ngZone: NgZone,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    public fb: FormBuilder
  ) {

    super(); //for base component

    //get user id
    this.auth.user$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((auth) => {
        if (auth) {
          this.userId = auth.uid;
          this.userName = auth.displayName;
          this.userPhoto = auth.photoURL;
          this.userEmail = auth.email;
        }
      });

    //get breadcrumb data
    this.bookingData = this.bookingSvc.getOptions();
    this.service = this.bookingData.step1;
    this.duration = this.bookingData.step3;
    this.address = this.bookingData.step4;
    this.timeDate = this.bookingSvc.getTherapistETA();
    this.room = this.bookingSvc.getRoom();
    this.therapist = this.bookingSvc.getTherapistName();

    //validate request
    this.valid = this.bookingSvc.validateBooking(this.service, this.duration); //sets price

    //init template
    this.template = '1';

    //console.log('is blocked' + this.bookingSvc.requestBlock);
  }

  // back(value) {
  //   this.stepperSvc.getResults();
  // }

  ngOnInit() {

    this.createAccount = this.fb.group({
      'firstname': ['', [
        Validators.required
      ]
      ],
      'lastname': ['', [
        Validators.required
      ]
      ],
      'email': ['', [
        Validators.required,
        Validators.email,
      ]
      ],
      'password': ['', [
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(6),
        Validators.maxLength(25),
        Validators.required
      ]
      ],
    });

  }

  bookingNotAccepted() {
    console.log('NOT ACCEPTED');
    //cancel booking
    this.bookingSvc.cancelBookingRequest(this.bookingSvc.getBookingId());
    //notify
    this.goTo('3');
  }

  switchForm() {
    if (this.formView == false) {
      this.formView = true;
    } else if (this.formView == true) {
      this.formView = false;
    }
  }

  createAcc() {
    // stop here if form is invalid
    if (this.createAccount.invalid) {
      alert('form invalid');
      return;
    } else {
      this.auth.emailSignUp(this.email.value, this.password.value, this.firstname.value, this.lastname.value);
      this.goTo('6');
    }
  }

  get firstname() { return this.createAccount.get('firstname') }
  get lastname() { return this.createAccount.get('lastname') }
  get email() { return this.createAccount.get('email') }
  get password() { return this.createAccount.get('password') }

  async signIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((credential) => {
        if (credential.user.emailVerified == true) {
          //console.log('email verified.. continue');
          this.createBooking();
        } else {
          alert('please verify email address');
        }
      }).catch((error) => {
        alert('Please check & try again');
      })
  }

  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.userId = credential.user.uid;
        this.userPhoto = credential.user.photoURL;
        this.userName = credential.user.displayName;
        this.createBooking();
        this.auth.updateUserData(credential.user)
      }).catch((error) => {

      });
  }

  async microsoftSignin() {
    const provider = new auth.OAuthProvider('microsoft.com');
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.userId = credential.user.uid;
        this.userPhoto = credential.user.photoURL;
        this.userName = credential.user.displayName;
        this.createBooking();
        this.auth.updateUserData(credential.user)
      }).catch((error) => {

      });
  }

  async facebookSignin() {
    const provider = new auth.FacebookAuthProvider();
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.userId = credential.user.uid;
        this.userPhoto = credential.user.photoURL;
        this.userName = credential.user.displayName;
        this.createBooking();
        this.auth.updateUserData(credential.user)
      }).catch((error) => {

      });
  }

  //request from cart view
  requestBooking() {

    //console.log(this.userEmail + ' - ' + this.userId + ' - ' + this.valid + ' - ' + localStorage.getItem('user'));
    //console.log('ReqBlock()' + this.bookingSvc.getReqBlock());

    //if already logged in else show login
    if ((this.userId) && (this.valid == true)) {
      // if (this.bookingSvc.getReqBlock() == 'true') {
      //   alert('please wait for last booking to be cancelled');
      //   //remove block
      //   setTimeout(() => { 
      //     localStorage.removeItem('requestBlock');
      //   }, 10000); //!!!set to 60000(1min)
      // } else {
      this.createBooking();
      this.template = '2';
      // }
    } else {
      this.template = '5';
    }

  }

  createBooking() {

    let record = {
      service: this.service,
      time: this.timeDate,
      duration: this.duration,
      location: {
        address: this.address,
        room: this.room
      },
      price: this.bookingSvc.getPrice(),
      date: new Date(),
      therapist: this.bookingSvc.getTherapist(),
      therapistName: this.bookingSvc.getTherapistName(),
      accepted: 0,
      user: {
        uid: this.userId,
        name: this.userName,
        photo: this.userPhoto
      },
      distance: this.bookingSvc.getDistance(),
      geo: {
        latitude: this.bookingSvc.getBookingLat(),
        longitude: this.bookingSvc.getBookingLng()
      }
    };

    this.bookingSvc.createBookingRequest(record).then(resp => {

      //console.log('booking id =' + resp.id);
      this.bookingId = resp.id;

      //set booking id for payment
      this.bookingSvc.setBookingId(this.bookingId);

      //set request block
      this.bookingSvc.setReqBlock(true);

      //send booking request to admin
      const callable = this.functions.httpsCallable('bookingRequestEmail');
      callable({
        bookingid: resp.id,
        name: record.user.name,
        subject: 'Customer booking request',
        eta: record.time,
        therapist: record.therapistName,
        room: record.location.room,
        address: record.location.address,
        service: record.service,
        duration: record.duration,
        price: record.price
      }).pipe(takeUntil(this.ngUnsubscribe)).subscribe();

      //listen for accepted booking
      this.bookingSvc.therapistResponse(this.bookingId).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          if (data) {
            this.reply = data;
            if (this.reply.accepted == 1) {
              this.bottomSheet.dismiss();
              this.router.navigate(['/my/bookings']);
            }
          }
        });

    }).catch(error => {
      console.log(error);
    });

  }

  async goTo(templateNo: string) {
    if (templateNo == '5') {
      this.formView = true;
    }
    this.template = templateNo;
  }

  //cancel request if user falls off
  ngOnDestroy(): void {
    if ((this.bookingId != undefined) && (this.reply.accepted == 0)) {
      this.bookingSvc.cancelBookingRequest(this.bookingSvc.getBookingId())
    }
  }

  // setAddress() {
  //   this.price = this.bookingSvc.getPrice(); //need to set price after validate
  //   this.room = this.roomElementRef.nativeElement.value;
  //   this.goTo(1);
  // }

  //HACK TO ACCEPT BOOKING
  // acceptBooking() {
  //   let record = {};
  //   record['accepted'] = true;
  //   this.bookingSvc.acceptBookingRequest(this.bookingId, record);
  //   this.bottomSheet.dismiss();
  //   this.router.navigate(['/my/bookings']);
  // }

}



//load Places Autocomplete
// this.mapsAPILoader.load().then(() => {

//   this.geoCoder = new google.maps.Geocoder;

//   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
//     types: ["establishment"]
//   });

//   autocomplete.addListener("place_changed", () => {
//     this.ngZone.run(() => {
//       //get the place result
//       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

//       //verify result
//       if (place.geometry === undefined || place.geometry === null) {
//         return;
//       }

//       this.address = place.formatted_address;
//       this.latitude = place.geometry.location.lat();
//       this.longitude = place.geometry.location.lng();
//       this.zoom = 18;
//     });
//   });

// });

// this.handler = StripeCheckout.configure({
//   key: environment.stripeKey,
//   //image: '/your/awesome/logo.jpg',
//   locale: 'auto',
//   token: token => {
//     //this.paymentSvc.processPayment(token, this.amount)
//     let record = {};
//     record['token'] = token;
//     record['amount'] = '500';
//     record['user'] = this.userId;
//     this.paymentSvc.processPayment(this.bookingId, record).then(resp => {
//       console.log(resp);
//     })
//       .catch(error => {
//         console.log(error);
//       });
//   }
// });

     // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       this.zoom = 18;
  //       this.getAddress(this.latitude, this.longitude);
  //     });
  //   }
  // }

  // getAddress(latitude, longitude) {
  //   this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //     console.log(results);
  //     console.log(status);
  //     if (status === 'OK') {
  //       if (results[0]) {
  //         this.zoom = 18;
  //         this.address = results[0].formatted_address;
  //       } else {
  //         window.alert('No results found');
  //       }
  //     } else {
  //       window.alert('Geocoder failed due to: ' + status);
  //     }

  //   });
  // }

  // @HostListener('window:popstate')
  // onPopstate() {
  //   this.handler.close()
  // }

  // handlePayment() {
  //   this.handler.open({
  //     name: 'BookSabai',
  //     excerpt: 'Payment',
  //     amount: this.amount
  //   });
  // }