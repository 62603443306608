import { DOCUMENT } from '@angular/common';
import { NgModule, ViewEncapsulation } from '@angular/core';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { Step5Component } from './step5/step5.component';
import { AuthService } from '../services/auth.service';
import { BookingService } from '../services/booking.service';
import { MatStepper } from '@angular/material/stepper';
import { MatBottomSheet } from '@angular/material';

import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  encapsulation: ViewEncapsulation.None // <------
})
@NgModule({
  imports: [
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component
  ]
})
export class BookComponent implements OnInit {

  tasks = [];
  show: boolean = false;
  closeShow: boolean = true;
  address: string;
  elem;

  exampleParent: string;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public auth: AuthService,
    public bookingSvc: BookingService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private locationStrategy: LocationStrategy,
    @Inject(DOCUMENT) private document: any
  ) {
    //attempt to hide hubspot
    //window.HubSpotConversations.widget.refresh();

    //prevent nav back
    //this.preventBackButton();

    //set address
    this.address = this.bookingSvc.getAddress();

    //check for address
    if (this.address == undefined) {
      this.router.navigate(["/"]);
    }

    //close full screen
    this.elem = document.documentElement;
  }

  ngOnInit() {
    this.elem = document.documentElement;
  }

  /* Close fullscreen */
  closeFullscreen() {
    this.closeShow = false;
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
      this.stepper.previous();
    })
  }

  handleClose() {

    //close fullscreen
    if (this.document.fullscreenElement || 
      this.document.webkitFullscreenElement || 
      this.document.mozFullScreenElement || 
      this.document.msFullscreenElement) {
      this.closeFullscreen();
    }

    //back to home
    if (this.stepper.selectedIndex == 0) {
      this.router.navigate(['/']);
    }

    // if (document.getElementsByClassName('cdk-global-overlay-wrapper').length > 0) {
    //   if (document.getElementById('working')) {
    //     if (confirm('Are you sure you want to cancel your booking request?')) {
    //       this.bottomSheet.dismiss();
    //       this.bookingSvc.cancelBookingRequest(this.bookingSvc.getBookingId());
    //       this.bookingSvc.setReqBlock(true);
    //     } else {
    //       // continue
    //     }
    //   } else {
    //     this.bottomSheet.dismiss(); //close bottomsheet 
    //     history.back();
    //   }
    // } else {
    //   this.router.navigate(['/']);
    // }
  }

  addBreadCrumb($event, index) {
    //console.log("add breadcrumb" + index);
    // this.exampleParent = $event;
    // this.tasks[index] = this.exampleParent;
    // this.bookingSvc.setOption('step' + index, this.exampleParent);
    if (this.stepper.selectedIndex > 0) {
      this.show = true;
      this.closeShow = false;
    }else{
      this.closeShow = true;
    }

    if (this.document.fullscreenElement || 
      this.document.webkitFullscreenElement || 
      this.document.mozFullScreenElement || 
      this.document.msFullscreenElement) {
      this.closeShow = true;
    } else {
      this.closeShow = false;
    }


  }

  removeBreadcrumb() {
    //to do
  }

  public move(index: number) {
    this.stepper.selectedIndex = index;
  }

  public goBack(stepper: MatStepper) {
    this.stepper.previous();
    if (this.stepper.selectedIndex == 0) {
      this.show = false;
      this.closeShow = true;
    }
  }

  public goForward(stepper: MatStepper) {
    this.stepper.next();
  }

}
