import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../services/auth.service';
import { BookingService } from '../../services/booking.service';
import { Router } from '@angular/router';
import { CheckoutComponent } from '../../checkout/checkout.component';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatBottomSheet } from '@angular/material';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';

//unsubscribe
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss']
})
export class Step5Component extends BaseComponent implements OnInit, OnDestroy {

  public serviceType;

  therapists: any[];

  loading: boolean;
  template: number;
  latitude: any;
  longitude: any;
  distance: number;
  available: number;
  reviews: boolean = false;
  reviewLink: string = "view reviews";

  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  public swiperConfig: SwiperConfigInterface = {
    observer: true,
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1.1,
    spaceBetween: 10,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    centeredSlides: true,
    navigation: false,
    pagination: false
  };

  constructor(
    private firestore: AngularFirestore,
    public bookingSvc: BookingService,
    private bottomSheet: MatBottomSheet,
    public auth: AuthService,
    private router: Router,
    private config: NgbRatingConfig,
    private mapsAPILoader: MapsAPILoader,
    private datePipe: DatePipe,
    private lightbox: CrystalLightboxModule
  ) {
    super(); //for base component
    //init ratings
    config.max = 5;
    config.readonly = true;
    //init template
    this.loading = true;
    this.template = 0;
  }

  ngOnInit() {

    //set booking geo
    this.setBookingLocation();

    //therapist list
    this.getTherapists(this.bookingSvc.getService()).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {

        //set available count
        if (data.length > 0) {
          this.available = data.length;

          //build list
          this.therapists = data.map(e => {
            if (e) {
              this.template = 1;
              this.loading = false;
              let lat1 = e.payload.doc.data()['geo']['Latitude'];
              let lng1 = e.payload.doc.data()['geo']['Longitude'];
              let dist = this.getDistance(lat1, lng1, this.latitude, this.longitude);
              let date = new Date();
              //arrival times
              if (dist < 3) {
                date.setMinutes(date.getMinutes() + 80);
              } else if (dist >= 3 && dist <= 10) {
                date.setMinutes(date.getMinutes() + 90);
              } else if (dist > 10) {
                date.setMinutes(date.getMinutes() + 90);
              }
              return {
                id: e.payload.doc.id,
                firstName: e.payload.doc.data()['name']['firstName'],
                lastName: e.payload.doc.data()['name']['lastName'],
                email: e.payload.doc.data()['email'],
                distance: Math.round(dist), //not tofixed!!
                presence: e.payload.doc.data()['presence'],
                photo: e.payload.doc.data()['documents']['photo']['file'],
                rating: e.payload.doc.data()['rating'],
                eta: this.datePipe.transform(date, "shortTime"),
                age: e.payload.doc.data()['profile']['age'],
                experience: e.payload.doc.data()['profile']['experience'],
                speciality: e.payload.doc.data()['profile']['speciality'],
                reviews: e.payload.doc.data()['reviews']
              };
            }
          });//end map

        } else {
          console.log('no therapist available');
          //no therapist available
          this.loading = false;
          this.template = 3;
        }

      });
  }

  //use for random review etc
  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getTherapists(serviceType: string) {
    return this.firestore.collection<any>('/therapists', ref => {
      return ref
        .where('presence', '==', 'online')
        .where('services', 'array-contains', serviceType);
    }).snapshotChanges();
  }

  selectTherapist(therapistId, distance, display, eta, photoURL, email) {
    if (distance < 15) {
      this.bookingSvc.setTherapist(therapistId, distance, display, eta, photoURL, email);
      this.openBottomSheet();
    } else {
      alert('therapist too far away');
    }
  }

  selectedIndex: number;
  viewReviews($event, index: number) {
    $event.preventDefault();
    this.selectedIndex = index;
    if (this.reviews == false) {
      this.reviews = true;
      //this.reviewLink = "hide reviews";
    } else {
      this.reviews = false;
      //this.reviewLink = "view reviews";
    }
  }

  openBottomSheet(): void {
    let sheet = this.bottomSheet.open(CheckoutComponent, { disableClose: false, closeOnNavigation: true });
    history.pushState(null, null, 'summary');
    sheet.backdropClick().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(x => {
        history.back();
      });
  }

  zoomPhoto() {
    history.pushState(null, null, 'zoom');
  }

  //NOT USED
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    }
  }

  setBookingLocation() {
    this.latitude = this.bookingSvc.getBookingLat();
    this.longitude = this.bookingSvc.getBookingLng();
    //console.log(this.bookingSvc.getBookingLat(), this.bookingSvc.getBookingLng());
  }

  getDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

}

