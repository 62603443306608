import { Component, AfterViewInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';
import { PaymentService } from '../../services/payment.service';
import { BookingService } from '../../services/booking.service';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

//unsubscribe
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';

declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent extends BaseComponent implements AfterViewInit {

  @Input() amount: number;
  @Input() description: string;
  @ViewChild('cardElement') cardElement: ElementRef;

  stripe; // : stripe.Stripe;
  card;
  cardErrors;

  loading = false;
  confirmation;

  template: number;
  therapist: string;
  price: number;
  therapistPhoto: string;
  statusHead: string;
  bookingData;
  charge;

  constructor(
    private auth: AuthService,
    private functions: AngularFireFunctions,
    private paymentSvc: PaymentService,
    private bookingSvc: BookingService,
    private router: Router,
    config: NgbRatingConfig
  ) {
    super(); //for base component
    // config.max = 5;
    // config.readonly = true;
    // this.statusHead = 'purple';
    this.template = 1;
    // this.therapist = this.bookingSvc.getTherapistName();
    // this.therapistPhoto = this.bookingSvc.getTherapistPhoto();
    // this.price = this.bookingSvc.getPrice();
  }

  ngAfterViewInit() {

    // this.stripe = Stripe(environment.stripeKey)
    // const elements = this.stripe.elements();

    // this.card = elements.create('card');
    // this.card.mount(this.cardElement.nativeElement);

    // this.card.addEventListener('change', ({ error }) => {
    //   this.cardErrors = error && error.message;
    // });

  }

  paymentNotMade() {
    //cancel booking 
    this.bookingSvc.cancelBookingRequest(this.bookingSvc.getBookingId());
    //remove block
    localStorage.removeItem('requestBlock');
    //prompt
    //alert('Booking cancelled. Payment not made.');
    //redirect to /book
    this.router.navigate(['/book']); //NEED TO REMOVE
  }

  async handleForm(e) {
    e.preventDefault();

    const { token, error } = await this.stripe.createToken(this.card);

    if (error) {
      // inform the customer that there was an error.
      const cardErrors = error.message;
      console.log(error.message);
    } else {

      this.loading = true;
      const user = await this.auth.getUser();

      let record = {};
      record['token'] = token;
      record['amount'] = this.bookingSvc.getPrice();
      record['user'] = user.uid;
      record['therapist'] = this.bookingSvc.getTherapist();

      //***process payment***
      (async () => {
        this.paymentSvc.processPayment(this.bookingSvc.getBookingId(), record);
      })().catch(err => {
        console.error(err);
      });

      //***payment complete*** (proceed if charge taken)
      (async () => {
        this.paymentSvc.completePayment(this.bookingSvc.getBookingId())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(data => {
            if (data) {
              //hide checkout
              this.statusHead = 'green';
              this.template = 1;
              this.loading = false;
              //remove block
              localStorage.removeItem('requestBlock');
              //send confirmation email
              this.bookingData = this.bookingSvc.getOptions();
              const callable = this.functions.httpsCallable('bookingConfirmationEmail');
              callable({
                name: this.auth.getUserName(),
                subject: 'Your booking has been confirmed',
                eta: this.bookingSvc.getTherapistETA(),
                therapist: this.bookingSvc.getTherapistName(),
                therapistEmail: this.bookingSvc.getTherapistEmail(),
                room: this.bookingSvc.getRoom(),
                address: this.bookingData.step4,
                service: this.bookingData.step1,
                duration: this.bookingData.step3,
                price: this.bookingSvc.getPrice()
              }).subscribe();
            }
          });
      })().catch(err => {
        this.cardErrors = "Sorry, there has been an issue with your payment. Please contact us via livechat on homepage";
        console.error(err);
      });
    }
  }

  //cancel request if user falls off
  ngOnDestroy(): void {
    // if (this.template == 0) {
    //   this.bookingSvc.cancelBookingRequest(this.bookingSvc.getBookingId())
    // }
  }

}
