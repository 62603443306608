import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-step0',
  templateUrl: './step0.component.html',
  styleUrls: ['./step0.component.scss']
})
export class Step0Component implements OnInit {

  constructor() {

  }

  @Output() step0Output = new EventEmitter<string>();

  getSelection(event: Event) {
    let elementId: string = (event.target as Element).id;
    this.step0Output.emit(elementId);
  }

  ngOnInit() {
  }

}
