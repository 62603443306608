import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { AppRoutingModule } from './app-routing.module';

//components
import { Step0Component } from './book/step0/step0.component';
import { Step1Component } from './book/step1/step1.component';
import { Step2Component } from './book/step2/step2.component';
import { Step3Component } from './book/step3/step3.component';
import { Step4Component } from './book/step4/step4.component';
import { Step5Component } from './book/step5/step5.component';
import { HomeComponent } from './home/home.component';
import { BookComponent } from './book/book.component';
import { LoginComponent } from './login/login.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { RegisterComponent } from './register/register.component';
import { WorkComponent } from './work/work.component';
import { DiscoverComponent } from './discover/discover.component'; //not used

//modules > User logged in area
import { MyModule } from './my/my.module';

//material UI
import { MaterialModule } from './material';

//firestore
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

//additional
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgPipesModule } from 'ngx-pipes';
import { DatePipe } from '@angular/common';
import { CountdownModule } from 'ngx-countdown';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';

//translations
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BaseComponent } from './base/base.component';
import { ShopsComponent } from './shops/shops.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

var firebaseConfig = {
  apiKey: "AIzaSyDeqXB3sofj3ruutQFnKK3_ZPP9swo1mgc",
  authDomain: "booksabai.com",
  databaseURL: "https://firestore-3983e.firebaseio.com",
  projectId: "firestore-3983e",
  storageBucket: "firestore-3983e.appspot.com",
  messagingSenderId: "538838970764"
};

@NgModule({
  declarations: [
    AppComponent,
    BookComponent,
    Step0Component,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    HomeComponent,
    LoginComponent,
    CheckoutComponent,
    DiscoverComponent,
    RegisterComponent,
    WorkComponent,
    BaseComponent,
    ShopsComponent
  ],
  imports: [
    MyModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgbTimepickerModule,
    NgbRatingModule,
    NgbCarouselModule,
    NgPipesModule,
    MaterialModule,
    SwiperModule,
    CountdownModule,
    CrystalLightboxModule,
    RecaptchaModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      //https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'AIzaSyDSqAMH2uGHbfKUzrO4LhoihXLIkG1UoNA',
      language: 'en', //change to th for thai
      libraries: ['geometry', 'places', 'geometry']
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage   
    AngularFireDatabaseModule, // needed??
    AngularFireFunctionsModule
  ],
  providers: [MDBSpinningPreloader, DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [CheckoutComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
