import { Component, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BookingService } from '../../services/booking.service';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from '@angular/fire/functions';
import { auth } from 'firebase/app';
import { AuthService } from '../../services/auth.service';
import { PaymentService } from '../../services/payment.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as firebase from 'firebase/app';

//unsubscribe
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';

declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component extends BaseComponent implements AfterViewInit, OnInit {

  @Input() amount: number;
  @Input() description: string;
  @ViewChild('cardElement') cardElement: ElementRef;

  stripe; // : stripe.Stripe;
  card;
  cardErrors;

  loading = false;
  emailCheck = false;
  confirmation;
  validatingForm: FormGroup;

  template: number;
  therapist: string;
  price: number;
  therapistPhoto: string;
  statusHead: string;
  charge;

  handler: any;
  service: string;
  duration: string;
  room: string;
  address: string;
  timeDate: string;
  reply: any;
  valid: boolean = false;
  authenticated: boolean = false;
  public show:boolean = false;
  //emailVerified: boolean = false;
  bookingId: string;
  loginErrors: string;

  userId: string;
  userPhoto: string;
  userName: string;
  userEmail: string;

  @Output() spinner = new EventEmitter<string>();
  @Output() step3Output = new EventEmitter<string>();

  constructor(
    private paymentSvc: PaymentService,
    public bookingSvc: BookingService,
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    public auth: AuthService,
    private router: Router
  ) {
    super(); //for base component
    //this.statusHead = 'purple';
    //get funnel data
    this.price = this.bookingSvc.getPrice();
    this.service = this.bookingSvc.getMassage();
    this.duration = this.bookingSvc.getDuration();
    this.address = this.bookingSvc.getAddress();
    this.timeDate = this.bookingSvc.getTime();
    this.room = this.bookingSvc.getRoom();
    //console.log(this.room);
    this.therapist = this.bookingSvc.getTherapistN();
    //get user data
    this.auth.user$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((auth) => {
        if (auth) {
          this.userId = auth.uid;
          this.userName = auth.displayName;
          this.userPhoto = auth.photoURL;
          this.userEmail = auth.email;
          this.authenticated = true;
        }else{
          this.show = true;
        }
      });
  }

  ngOnInit() {
    //validate email
    this.validatingForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  get input() { return this.validatingForm.get('email'); }

  ngAfterViewInit() {

    this.stripe = Stripe(environment.stripeKey)
    const elements = this.stripe.elements();

    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });

  }

  async createBooking() {

    let record = {
      service: this.service,
      time: this.timeDate,
      duration: this.duration,
      location: {
        address: this.address,
        room: this.room
      },
      price: this.bookingSvc.getPrice(),
      date: new Date(),
      therapist: '',
      therapistName: this.therapist,
      accepted: 0,
      user: {
        uid: this.userId,
        name: this.userName,
        photo: this.userPhoto
      },
      distance: '',
      geo: {
        latitude: this.bookingSvc.getBookingLat(),
        longitude: this.bookingSvc.getBookingLng()
      }
    };

    return this.bookingSvc.createBookingRequest(record).then(resp => {

      //console.log('booking id =' + resp.id);
      this.bookingId = resp.id;

      //set booking id for payment
      this.bookingSvc.setBookingId(this.bookingId);

      //send booking request to admin
      const callable = this.functions.httpsCallable('bookingRequestEmail');
      callable({
        bookingid: resp.id,
        name: record.user.name,
        subject: 'Customer booking request',
        eta: record.time,
        therapist: record.therapistName,
        room: record.location.room,
        address: record.location.address,
        service: record.service,
        duration: record.duration,
        price: record.price
      }).pipe(takeUntil(this.ngUnsubscribe)).subscribe();


    }).catch(error => {
      console.log(error);
    });

  }

  async handleForm(e) {
    e.preventDefault();

    const { token, error } = await this.stripe.createToken(this.card);

    if (error) {
      // inform the customer that there was an error.
      const cardErrors = error.message;
      console.log(error.message);
    } else {

      this.loading = true;
      const user = await this.auth.getUser();

      let record = {};
      record['token'] = token;
      record['amount'] = this.bookingSvc.getPrice();
      record['user'] = user.uid;
      record['therapist'] = '';

      //***process payment***
      (async () => {
        this.paymentSvc.processPayment(this.bookingSvc.getBookingId(), record);
      })().catch(err => {
        console.error(err);
      });

      //***payment complete*** (proceed if charge taken)
      (async () => {
        this.paymentSvc.completePayment(this.bookingSvc.getBookingId())
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(data => {
            if (data) {
              //create booking 
              this.createBooking();
              //hide checkout
              this.loading = false;
              //send confirmation email
              const callable = this.functions.httpsCallable('bookingConfirmationEmail');
              callable({
                name: this.auth.getUserName(),
                subject: 'Your booking has been confirmed',
                eta: this.bookingSvc.getTime(),
                therapist: 'Therapist',
                therapistEmail: 'therapist@booksabai.com',
                room: this.bookingSvc.getRoom(),
                address: this.bookingSvc.getAddress(),
                service: this.bookingSvc.getMassage(),
                duration: this.bookingSvc.getDuration(),
                price: this.bookingSvc.getPrice()
              }).subscribe();
              //go to my bookings
              this.router.navigate(['/my/bookings']);
            }
          });
      })().catch(err => {
        this.cardErrors = "Sorry, there has been an issue with your payment. Please contact us via livechat on homepage";
        console.error(err);
      });

    }
  }

  async handleCash(){
    //console.log('handle cash');
    this.loading = true;
    const booking = await this.createBooking();
    this.router.navigate(['/my/bookings']);
  }

  userProvider(email) {
    var provider;
    return firebase.auth().fetchSignInMethodsForEmail(email)
      .then((list) => {
        provider = list.toString();
        return provider;
      });
  }

  userExists(email) {

    const callable = this.functions.httpsCallable('checkUserExists');
    return callable({
      email: email
    }).toPromise().then(
      resp => {
        //alert(JSON.stringify(resp, null, 4));
        if (resp == null) {
          return false;
        } else {
          return true;
        }
      },
      err => {
        //alert(JSON.stringify(err, null, 4)); 
        return false;
      });

  }

  sendWelcomeEmail(){
    //console.log('sendWelcomeEmail');
    // const callable = this.functions.httpsCallable('customerWelcomeEmail');
    // return callable({
    //   name: name,
    //   subject: 'Welcome to BookSabai!',
    // }).subscribe();

  }

  async verifyEmail(){

    //get input
    const email = this.validatingForm.get('email').value; 

    //loading 
    this.emailCheck = true; 

    //user already?
    const exists = await this.userExists(email);
    const provider = await this.userProvider(email);

    //console.log(exists);

    if (exists == true) {
      if (provider == 'password') {
        return this.emailLogin(email, email); 
      }else{
        this.loginErrors = 'User already exists. Please try logging in with Facebook.';
        this.validatingForm.reset();
      }
    } else {
      //first time log in
      const credential = await this.afAuth.auth.createUserWithEmailAndPassword(email, email);
      this.authenticated = true;
      this.sendWelcomeEmail();
    }

    //reset
    this.emailCheck = false; 

  }

  async emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((credential) => {
        this.userId = credential.user.uid;
        this.userPhoto = credential.user.photoURL;
        this.userName = credential.user.displayName;
        this.authenticated = true;
        this.auth.updateUserData(credential.user)
      }).catch(function (error) {
        //console.log(error.code);
        //console.log(error.message);
        throw error;
      });
  }

  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.userId = credential.user.uid;
        this.userPhoto = credential.user.photoURL;
        this.userName = credential.user.displayName;
        //this.createBooking();
        this.auth.updateUserData(credential.user)
      }).catch((error) => {

      });
  }

  async microsoftSignin() {
    const provider = new auth.OAuthProvider('microsoft.com');
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.userId = credential.user.uid;
        this.userPhoto = credential.user.photoURL;
        this.userName = credential.user.displayName;
        this.auth.updateUserData(credential.user)
      }).catch((error) => {

      });
  }

  async facebookSignin() {
    const provider = new auth.FacebookAuthProvider();
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.userId = credential.user.uid;
        this.userPhoto = credential.user.photoURL;
        this.userName = credential.user.displayName;
        this.auth.updateUserData(credential.user);
        if(credential.additionalUserInfo.isNewUser == true){
          this.sendWelcomeEmail();
        }
      }).catch((error) => {
        this.loginErrors = 'User already exists. Please try logging in with Email address.';
      });
  }

  getSelection($event, payment) {
    history.pushState(null, null, payment);
    this.step3Output.emit(payment);
  }

}
