import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { BookingService } from '../../services/booking.service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {

  public radioModel = 'Left';
  @Output() step2Output = new EventEmitter<string>();

  public now: Date = new Date();
  hourx: number = this.now.getHours() + 1;
  minx: number = this.now.getMinutes();

  minAdd: number;

  time: NgbTimeStruct = { hour: this.hourx, minute: 0, second: 0 };
  hourStep = 1;
  minuteStep = 30;

  constructor(
    public bookingSvc: BookingService
  ) {
    //history.pushState(null, null, 'time');
  }


  timeBuffer() {

  }

  ctrl = new FormControl('', (control: FormControl) => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (value.hour < 12) {
      return { tooEarly: true };
    }
    if (value.hour > 13) {
      return { tooLate: true };
    }

    return null;
  });

  getSelection(event: Event) {

    // let day = document.getElementsByClassName("active")[0].id;

    // if (day == 'tomorrow') {
    //   var noted = ' *';
    // } else {
    //   var noted = '';
    // }

    this.step2Output.emit('time');
    let time = this.time.hour + ':' + this.time.minute;
    console.log(time);
    this.step2Output.emit('time');
    this.bookingSvc.setTime(time);

  }

  ngOnInit() {
  }

}
