import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-my',
  templateUrl: './my.component.html',
  styleUrls: ['./my.component.scss']
})
@NgModule({
  imports: [

  ]
})
export class MyComponent implements OnInit {

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }


}
