import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../services/booking.service';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss'],
  encapsulation: ViewEncapsulation.None // <------
})
export class ShopsComponent implements OnInit {

  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  public shops: any;
  public address: string;

  public config: SwiperConfigInterface = {
    observer: true,
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1.25,
    spaceBetween: 10,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    centeredSlides: false,
    navigation: false,
    pagination: false
  };

  constructor(
    private firestore: AngularFirestore,
    private ratings: NgbRatingConfig,
    public bookingSvc: BookingService,
    private router: Router
  ) {

    //get address
    this.address = bookingSvc.getAddress();

    //check for address
    if (this.address == undefined) {
      this.router.navigate(["/"]);
    }

    //get slides
    this.getShopDetails().pipe()
      .subscribe(data => {
        this.shops = data.map(e => {
          return {
            id: e.payload.doc.id,
            name: e.payload.doc.data()['name'],
            rating: e.payload.doc.data()['rating'],
            address: e.payload.doc.data()['address'],
            image: e.payload.doc.data()['image'],
            online: e.payload.doc.data()['online'],
            row: e.payload.doc.data()['row']
          };
        })
      });

    //init ratings
    ratings.max = 5;
    ratings.readonly = true;

    //create record in db
    // this.bookingSvc.addShop();

  }

  ngOnInit() {
  }

  getShopDetails() {
    return this.firestore.collection('shops').snapshotChanges();
    // return this.firestore.collection<any>('/shops', ref => {
    //   return ref
    //     .where('online', '==', 'true')
    // }).valueChanges();
  }

  getSelection(shopName) {
    if (shopName != '') {
      this.bookingSvc.setShop(shopName);
      this.router.navigate(['/book']);
    }
  }

  public onIndexChange(index: number): void {

  }

}
