import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnDestroy {

  constructor() { }

  ngUnsubscribe = new Subject<void>();

  ngOnDestroy(): void {
    //console.log('destroyed kap!')
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
