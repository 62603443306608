import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

interface Therapist {
  uid: string;
  email: string;
  name?: object;
  firstName?: string;
  lastName?: string;
  phone?: number;
  city?: string;
  verified?: boolean;
  documents?: object;
  cert?: string;
  id?: string;
  photo?: string;
  presence?: string;
  rating?: number;
  services?: Array<any>;
  stats?: object;
  location?: object;
  geo?: object;
  Latitude?: number;
  Longitude?: number;
  reviews?: Array<any>;
  profile?: object;
  age?: string;
  experience?: string;
}

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
  encapsulation: ViewEncapsulation.None // <------
})
export class WorkComponent implements OnInit {

  therapist$: Observable<Therapist>; //doesnt need to be observable??

  signupForm: FormGroup;
  submitted = false;
  public template: number = 0;
  private captcha: string = null;
  public language: string;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private functions: AngularFireFunctions,
    public fb: FormBuilder,
    private translate: TranslateService,
    private config: NgbCarouselConfig
  ) {
    this.translate.setDefaultLang(this.language = 'th');
    this.config.wrap = true;
    this.config.keyboard = false;
    this.config.pauseOnHover = false;
  }

  resolved(captchaResponse: string) {
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captcha = captchaResponse;
  }

  ngOnInit() {

    this.signupForm = this.fb.group({
      'email': ['', [
        Validators.required
      ]
      ],
      'firstname': ['', [
        Validators.required
      ]
      ],
      'lastname': ['', [
        Validators.required
      ]
      ],
      'phone': ['', [
        Validators.required
      ]
      ],
      // 'password': ['', [
      //   Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
      //   Validators.minLength(6),
      //   Validators.maxLength(25),
      //   Validators.required
      // ]
      // ],
      'region': ['', [
      ]
      ],
    });

  }

  signup() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    }

    if (this.captcha == null) {
      alert('Are you a robot?');
    } else {
      this.template = 1;
      return this.emailSignUp();
    }
  }

  async emailSignUp() {
    const email = this.signupForm.get('phone').value+"@booksabai.com";
    console.log(email);
    const credential = await this.afAuth.auth.createUserWithEmailAndPassword(email, "pa55word"); 
    //this.sendEmailVerification();
    //this.sendWelcomeEmail();
    return this.setUserDoc(credential.user);
  }

  async sendWelcomeEmail() {
    const callable = this.functions.httpsCallable('therapistRegistrationEmail');
    callable({
      name: this.firstname.value
    }).subscribe();
  }

  async sendEmailVerification() {
    await this.afAuth.auth.currentUser.sendEmailVerification();
    console.log('verification email sent');
  }

  private handleError(error) {
    console.error(error)
    //this.notify.update(error.message, 'error')
  }

  // Sets user data to firestore after succesful registration
  private setUserDoc(therapist) {

    const therapistRef: AngularFirestoreDocument<Therapist> = this.afs.doc(`therapists/${therapist.uid}`);

    const data: Therapist = {
      uid: therapist.uid,
      email: therapist.email,
      name: {
        firstName: this.firstname.value,
        lastName: this.lastname.value
      },
      phone: this.phone.value,
      city: 'Bangkok',
      verified: false,
      documents: {
        ID: {
          uploaded: true,
          file: this.signupForm.get('email').value
        },
        cert: {
          uploaded: true,
          file: 'file'
        },
        photo: {
          uploaded: true,
          file: 'file'
        }
      },
      presence: 'offline',
      rating: 0,
      services: [
        'thai massage',
        'oil massage',
        'aromatherapy',
        'foot massage'
      ],
      stats: {
        earned: 0,
        sessions: 0,
        jobs: 0
      },
      location: {
        room: null,
        address: null
      },
      geo: {
        Latitude: 0,
        Longitude: 0,
      },
      reviews: [
        null
      ],
      profile: {
        age: '25',
        experience: '5',
      },
    }

    return therapistRef.set(data, { merge: true })

  }

  // Using getters 

  get f() { return this.signupForm.controls; }

  get firstname() { return this.signupForm.get('firstname') }
  get lastname() { return this.signupForm.get('lastname') }
  get email() { return this.signupForm.get('email') }
  get password() { return this.signupForm.get('password') }
  get phone() { return this.signupForm.get('phone') }
  get city() { return this.signupForm.get('city') }

  public changeLanguage(): void {
    this.language = (this.language == 'en') ? 'th' : 'en';
    this.translate.use(this.language);
  }

}
