import { Component, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../services/auth.service';
import { BookingService } from '../../services/booking.service';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';

//unsubscribe
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component extends BaseComponent implements AfterViewInit {

  //radio buttons
  // public people: string = 'one';
  // public duration: string = '1';
  // public gender: string = 'female';

  displayPrice: number = 800;
  serviceName: string = 'Body & Soul';
  duration: number = 90;

  public selectedTherapist: string;
  public buttonDisabled: boolean = true;
  public shops: any;

  public serviceType;

  therapists: any[];

  loading: boolean;
  latitude: any;
  longitude: any;
  distance: number;
  available: number;
  reviews: boolean = true;
  reviewLink: string = "view reviews";

  services: any;
  studentName: string;
  studentAge: number;
  studentAddress: string;
  template: number;
  elementId: any;

  @ViewChild('room')
  public roomElementRef: ElementRef;

  @Output() step1Output = new EventEmitter<string>();

  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  public config: SwiperConfigInterface = {
    observer: true,
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1.1,
    spaceBetween: 10,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    centeredSlides: true,
    navigation: false,
    pagination: false
  };

  constructor(
    private firestore: AngularFirestore,
    public bookingSvc: BookingService,
    public auth: AuthService
  ) {
    super(); //for base component
    //this.template = 0;
    // history.pushState(null, null, 'details');

    //get slides
    this.getServices().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.services = data.map(e => {
          this.template = 1;
          return {
            id: e.payload.doc.id,
            name: e.payload.doc.data()['name'],
            description: e.payload.doc.data()['description'],
            photoURL: e.payload.doc.data()['photoURL'],
            price: e.payload.doc.data()['price'],
            duration: e.payload.doc.data()['duration']
          };
        })
      });


    //get slides
    //therapist list
    this.getTherapists(this.bookingSvc.getService()).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {

        //set available count
        if (data.length > 0) {
          this.available = data.length;

          //build list
          this.therapists = data.map(e => {
            if (e) {
              this.template = 1;
              this.loading = false;
              let lat1 = e.payload.doc.data()['geo']['Latitude'];
              let lng1 = e.payload.doc.data()['geo']['Longitude'];
              //let dist = this.getDistance(lat1, lng1, this.latitude, this.longitude);
              let date = new Date();
              //arrival times
              // if (dist < 3) {
              //   date.setMinutes(date.getMinutes() + 80);
              // } else if (dist >= 3 && dist <= 10) {
              //   date.setMinutes(date.getMinutes() + 90);
              // } else if (dist > 10) {
              //   date.setMinutes(date.getMinutes() + 90);
              // }
              return {
                id: e.payload.doc.id,
                firstName: e.payload.doc.data()['name']['firstName'],
                lastName: e.payload.doc.data()['name']['lastName'],
                email: e.payload.doc.data()['email'],
                //distance: Math.round(dist), //not tofixed!!
                presence: e.payload.doc.data()['presence'],
                photo: e.payload.doc.data()['documents']['photo']['file'],
                rating: e.payload.doc.data()['rating'],
                //eta: this.datePipe.transform(date, "shortTime"),
                age: e.payload.doc.data()['profile']['age'],
                experience: e.payload.doc.data()['profile']['experience'],
                speciality: e.payload.doc.data()['profile']['speciality'],
                reviews: e.payload.doc.data()['reviews']
              };
            }
          });//end map

        } else {
          console.log('no therapist available');
          //no therapist available
          this.loading = false;
          this.template = 3;
        }

      });

    //init prices
    //this.showPrice();
  }

  ngAfterViewInit() {

  }

  getTherapists(serviceType: string) {
    return this.firestore.collection<any>('/therapists', ref => {
      return ref
        .where('presence', '==', 'online')
        //.where('services', 'array-contains', 'foot massage');
    }).snapshotChanges();
  }


  selectedIndex: number;
  viewReviews($event, index: number) {
    $event.preventDefault();
    this.selectedIndex = index;
    if (this.reviews == false) {
      this.reviews = true;
      //this.reviewLink = "hide reviews";
    } else {
      this.reviews = false;
      //this.reviewLink = "view reviews";
    }
  }

  getShopDetails() {
    return this.firestore.collection('shops').snapshotChanges();
    // return this.firestore.collection<any>('/shops', ref => {
    //   return ref
    //     .where('online', '==', 'true')
    // }).valueChanges();
  }

  getDurationPrice() {
    //console.log(this.duration);
    //this.showPrice();
  }

  getServices() {
    // return this.firestore.collection('services').snapshotChanges();
    return this.firestore.collection<any>('/services', ref => {
      return ref
        .where('available', '==', 'true');
    }).snapshotChanges();
  }

  showPrice() {
    // console.log(this.serviceName);
    // console.log(this.duration);
    // this.bookingSvc.getServiceDetails(this.serviceName)
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((data) => {
    //     if (data) {
    //       let reply = data;
    //       this.displayPrice = (reply[0].matrix[this.duration])// get price by duration
    //     }
    //   });
  }

  selectService() {
    this.step1Output.emit(this.serviceName);
    // console.log(this.serviceName);
    // console.log(this.displayPrice);
    // console.log(this.duration);
    // console.log(this.roomElementRef.nativeElement.value);
    this.bookingSvc.setMassage(this.serviceName);
    this.bookingSvc.setPrice(this.displayPrice);
    this.bookingSvc.setDuration(this.duration);
    this.bookingSvc.setRoom(this.roomElementRef.nativeElement.value);
    //get selected therapist
    var nodes = document.querySelectorAll(".swiper-slide-active .name");
    var list = [].slice.call(nodes);
    this.selectedTherapist = list.map(function(e) { return e.innerText; }).join("\n");
    this.bookingSvc.setTherapistN(this.selectedTherapist);
  }

  handleBtn() {
    //check input & enable btn
    if (this.roomElementRef.nativeElement.value != '') {
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
      //this.roomElementRef.nativeElement.focus();
    }
  }

  public onServiceChange(index: number): void {
    // console.log('Swiper index: ', index);
    switch (index) {
      case 0:
        this.serviceName = 'Body & Soul';
        this.displayPrice = 800;
        this.duration = 90;
        break;
      case 1:
        this.serviceName = 'Day Dreamer';
        this.displayPrice = 1000;
        this.duration = 120;
        break;
      case 2:
        this.serviceName = 'Nirvana';
        this.displayPrice = 1400;
        this.duration = 150;
        break;
      default:
        this.serviceName = 'Body & Soul';
        this.displayPrice = 800;
        this.duration = 90;
    }
    //update price
    //this.showPrice();
  }

  public onTherapistChange(index: number): void {
    this.reviews = false;
  }

}
