import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public auth: AuthService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (localStorage.getItem('user')) {
      // logged in so return true
      return true;
    } else {
      this.router.navigate(['/home']);
    }
  }

}
