import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, EventEmitter } from '@angular/core';
import { BookingService } from '../../services/booking.service';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss']
})
export class Step4Component implements OnInit {

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  buttonDisabled: boolean = true;
  inputRoom: boolean = false;
  floatButton: boolean = false;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('room')
  public roomElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public bookingSvc: BookingService
  ) {
    //history.pushState(null, null, 'location');
  }

  public style = [
    {
      "featureType": "administrative.country",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    }
  ]

  ngOnInit() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      //this.setCurrentLocation();

      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["establishment"],
        componentRestrictions: { country: 'th' }
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 18;
          //set booking geo
          this.bookingSvc.setBookingGeo(this.latitude, this.longitude);
          //console.log(this.latitude, this.longitude);
          //enable button
          if ((this.latitude) && (this.longitude)) {
            this.inputRoom = true
            //this.searchElementRef.nativeElement.focus();
          }
        });
      });
    });
  }

  handleBtn() {
    //check input & enable btn
    if ((this.searchElementRef.nativeElement.value != '') && (this.roomElementRef.nativeElement.value != '')) {
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
    }
  }

  getSelection(event: Event) {
    if ((this.searchElementRef.nativeElement.value != '') && (this.roomElementRef.nativeElement.value != '')) {
      this.buttonDisabled = false;
      this.bookingSvc.setRoom(this.roomElementRef.nativeElement.value);
      this.bookingSvc.setAddress(this.searchElementRef.nativeElement.value);
    }
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 18;
        this.getAddress(this.latitude, this.longitude);
      }, (error) => {
        alert('Cannot access your location');
      }, { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true });
    }
  }

  markerDragEnd($event: MouseEvent) {
    // console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

}
