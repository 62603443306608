import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private data = {};
  private therapist = {};
  public bookingGeo = {};
  public bookingId: string;
  public price: number;
  public therapistN: string;
  public reply: any;
  public address: string;
  public room: string;
  public time: string;
  public massage: string;
  public duration: string;
  public shop: string;
  public requestBlock: boolean;

  constructor(
    private firestore: AngularFirestore
  ) { }

  //set breadcrumbs
  setOption(option, value) {
    this.data[option] = value;
  }

  //get breadcrumbs
  getOptions() {
    return this.data;
  }

  //for therapist query - not needed - refactor
  getService() {
    return this.data['step1'];
  }

  setTherapist(id, dist, display, eta, photoURL, email) {
    this.therapist['id'] = id;
    this.therapist['dist'] = dist;
    this.therapist['name'] = display;
    this.therapist['eta'] = eta;
    this.therapist['photo'] = photoURL;
    this.therapist['email'] = email;
  }

  getTherapist() {
    return this.therapist['id'];
  }

  getTherapistName() {
    return this.therapist['name'];
  }

  getTherapistETA() {
    return this.therapist['eta'];
  }

  getTherapistPhoto() {
    return this.therapist['photo'];
  }

  getTherapistEmail() {
    return this.therapist['email'];
  }

  getDistance() {
    return this.therapist['dist'];
  }

  setBookingGeo(lat, lng) {
    this.bookingGeo['lat'] = lat;
    this.bookingGeo['lng'] = lng;
  }

  getBookingLat() {
    return this.bookingGeo['lat'];
  }

  getBookingLng() {
    return this.bookingGeo['lng'];
  }

  setBookingId(bookingid) {
    this.bookingId = bookingid;
  }

  getBookingId() {
    return this.bookingId;
  }

  setMassage(massage) {
    this.massage = massage;
  }

  getMassage() {
    return this.massage;
  }

  setDuration(duration) {
    this.duration = duration;
  }

  getDuration() {
    return this.duration;
  }

  setPrice(price) {
    this.price = price;
  }

  getPrice() {
    return this.price;
  }

  setShop(shop) {
    this.shop = shop;
  }

  getShop() {
    return this.shop;
  }

  setAddress(address) {
    this.address = address;
  }

  getAddress() {
    return this.address;
  }

  setRoom(room) {
    this.room = room;
  }

  getRoom() {
    return this.room;
  }

  setTime(time) {
    this.time = time;
  }

  getTime() {
    return this.time;
  }

  setTherapistN(therapist) {
    this.therapistN = therapist;
  }

  getTherapistN() {
    return this.therapistN;
  }

  setReqBlock(val: boolean) {
    localStorage.setItem('requestBlock', 'true');
  }

  getReqBlock() {
    return localStorage.getItem('requestBlock');
  }

  getServiceDetails(service) {

    return this.firestore.collection<any>('/services', ref => {
      return ref
        .where('name', '==', service)
    }).valueChanges();

  }

  validateBooking(service, duration) {

    this.getServiceDetails(service).subscribe((data) => {
      if (data) {
        this.reply = data;
        this.setPrice(this.reply[0].matrix[duration])// get price by duration
      }
    });

    //return if valid
    if (this.setPrice != undefined) {
      return true;
    }

  }

  cancelBookingRequest(bookingid) {
    console.log('cancel booking');
    //update accepted
    let accepted = {};
    accepted['accepted'] = 5;
    this.firestore.doc('bookingRequests/' + bookingid).update(accepted);
  }

  createBookingRequest(record) {
    return this.firestore.collection('bookingRequests').add(record);
  }

  therapistResponse(bookingid) {
    return this.firestore.doc(`bookingRequests/${bookingid}`).valueChanges();
  }

  addShop() {
    let record = {
      name: "",
      address: "",
      image: "",
      online: false,
      rating: ""
    };

    return this.firestore.collection('shops').add(record);
  }

}