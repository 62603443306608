import { DOCUMENT } from '@angular/common';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Component, OnInit, NgZone, ElementRef, ViewChild, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../services/booking.service';

//unsubscribe
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {

  public therapists: any[];
  public show: string;
  public geoCoder;
  public latitude: number;
  public longitude: number;
  public zoom: number;

  elem;

  @ViewChild('search')
  public searchElementRef: ElementRef

  constructor(
    private firestore: AngularFirestore,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private lightbox: CrystalLightboxModule,
    private config: NgbRatingConfig,
    public bookingSvc: BookingService,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {
    super(); //for base component
    //init ratings
    config.max = 5;
    config.readonly = true;
  }

  ngOnInit() {


    this.elem = document.documentElement;
    
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      //this.setCurrentLocation();

      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["establishment"],
        componentRestrictions: { country: 'th' }
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 18;

          //set booking geo
          this.bookingSvc.setBookingGeo(this.latitude, this.longitude);
          
          //console.log(this.latitude, this.longitude);
          
        });
      });
    });

    //therapist list
    this.getTherapists()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        //set available count
        if (data.length > 0) {
          //build list
          this.therapists = data.map(e => {
            if (e) {
              return {
                firstName: e.payload.doc.data()['name']['firstName'],
                photo: e.payload.doc.data()['documents']['photo']['file'],
                rating: e.payload.doc.data()['rating']
              };
            }
          });//end map
        } else {
          console.log('no therapist available');
          //no therapist available
        }
      });

  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  getTherapists() {
    return this.firestore.collection<any>('/therapists', ref => {
      return ref
        .where('presence', '==', 'online');
    }).snapshotChanges();

    //return this.firestore.collection<any>('/therapists').snapshotChanges();
  }

  getSelection() {

    if (this.latitude == null || this.longitude == null) {
      this.searchElementRef.nativeElement.focus();
    } else {
      this.openFullscreen();
      this.bookingSvc.setAddress(this.searchElementRef.nativeElement.value);
      this.router.navigate(['/book']);
    }
  }

}
