import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { compileBaseDefFromMetadata } from '@angular/compiler';

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
}

@Injectable({ providedIn: 'root' })

export class AuthService {

  user$: Observable<User>;
  userName: string;
  public loggedIn = false;
  authState = new BehaviorSubject(false);

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {

    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          // Logged in & email verified
          //if ((user.providerData[0].providerId == 'password') && (user.emailVerified == false)) {
          //if (user.providerData[0].providerId == 'password') {
            //return of(null);
          //} else {
            this.authState.next(true); //for auth guard
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          //}
        } else {
          // Logged out
          return of(null);
        }
      })
    )

  }//end constructor

  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async microsoftSignin() {
    const provider = new auth.OAuthProvider('microsoft.com');
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async facebookSignin() {
    const provider = new auth.FacebookAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }

  async signIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((credential) => {
        if (credential.user.emailVerified == true) {
          console.log('email verified.. continue');
        } else {
          console.log('email not verified');
        }
      }).catch((error) => {

      })
  }

  async emailSignUp(email: string, password: string, firstname: string, lastname: string) {
    const credential = await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    await this.sendEmailVerification();
    await this.afAuth.auth.signOut();
    return this.updateUserBasic(credential.user, firstname, lastname);
  }

  async passswordReset(email) {
    await this.afAuth.auth.sendPasswordResetEmail(email).then(function () {
      alert('Please check inbox');
    }).catch(function (error) {
      // An error happened.
    });
  }

  async sendEmailVerification() {
    await this.afAuth.auth.currentUser.sendEmailVerification();
    //console.log('verification email sent');
  }

  async checkEmailVerified() {
    return this.afAuth.auth.currentUser.emailVerified;
  }

  public updateUserBasic(user, firstname, lastname) {
    // sets user data for email login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data = {
      uid: user.uid,
      email: user.email,
      displayName: firstname + ' ' + lastname,
      photoURL: null
    }

    localStorage.setItem('user', user.email);
    this.loggedIn = true;

    return userRef.set(data, { merge: true })

  }

  public updateUserData(user) {
    // sets user data on social login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    }

    localStorage.setItem('user', user.email);
    this.loggedIn = true;

    return userRef.set(data, { merge: true })

  }

  async getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  setUserName(name) {
    this.userName = name;
  }

  getUserName() {
    return this.afAuth.auth.currentUser.displayName;
  }

  async isLoggedIn() {
    return this.loggedIn;
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('user');
    return this.router.navigate(['/']);
  }

  isAuthenticated() {
    return this.authState.value;
  }

}